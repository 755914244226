import _objectSpread from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
var AuthRepository = RepositoryFactory.get('auth');
export default {
  name: 'user-history',
  components: (_components = {}, _defineProperty(_components, _Dialog.name, _Dialog), _defineProperty(_components, _Pagination.name, _Pagination), _components),
  data: function data() {
    return {
      activities: [],
      pagination: {},
      currentPage: 1,
      viewModel: {},
      visible: false,
      searchCriteria: null,
      user: {}
    };
  },
  computed: _objectSpread({}, mapGetters({
    facility: 'facility/facility'
  })),
  methods: {
    /** Retrieve messages from server */
    fetchActivity: function fetchActivity() {
      var _this = this;

      AuthRepository.getActivity(this.$route.params.user_id, this.currentPage).then(function (r) {
        _this.activities = _.pick(r.data, 'data').data;
        _this.pagination = _.omit(r.data, 'data');
      });
    },
    fetchUser: function fetchUser() {
      var _this2 = this;

      AuthRepository.getUserById(this.$route.params.user_id, true).then(function (r) {
        _this2.user = r.data;
      });
    },

    /** View requested message */
    showModel: function showModel(activity) {
      // MessageRepository.getMessage(message_id)
      // .then(r => {
      this.viewModel = activity;
      this.visible = true; // })
    },
    closeDetails: function closeDetails() {
      this.viewModel = {};
      this.visible = false;
    },
    page: function page(_page) {
      this.currentPage = _page;
      this.fetchActivity();
    },
    filterChanges: function filterChanges(changes) {
      var ignore = ['created_at', 'updated_at', 'deleted_at'];
      return _.pickBy(changes.attributes, function (v, k) {
        return !ignore.includes(k);
      });
    } // search() {
    //     MessageRepository.search(this.searchCriteria)
    //     .then(r => {
    //         this.messages = _.pick(r.data, 'data').data;
    //         this.pagination = _.omit(r.data, 'data');
    //     })
    // },
    // clearSearch() {
    //     this.currentPage = 1;
    //     this.searchCriteria = null;
    //     this.fetchActivity();
    // }

  },
  created: function created() {
    this.fetchActivity();
    this.fetchUser();
  },
  mounted: function mounted() {},
  watch: {}
};